<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row>
      <v-col cols="12">
        <v-btn color="teal lighten-1 float-right" depressed dark @click="newUser">
                  <v-icon color="white">mdi-account-plus</v-icon>New User
                </v-btn>
                <UserDialog
                  :dialog-open="dialogOpen"
                  :dialog-type="dialogType"
                  :dialog-types="dialogTypes"
                  :item-to-edit="itemToEdit"
                  @closeDialog="dialogOpen = false"
                />
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined>
          <v-data-table
            :headers="headers"
            :items="users"
            sort-by="type"
            class="elevation-0"
            :search="search"
            :loading="userLoading"
            loading-text="Loading users..."
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Users</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>     
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="editUser(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit User</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="deleteUserClick(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '@layouts/main'
import UserDialog from '../../components/Users/UsersDialog.vue'

export default {
  name: 'Users',
  components: { Layout, UserDialog },
  data: () => ({
    dialogOpen: false,
    dialogType: null,
    dialogTypes: {
      NEW: 'new',
      EDIT: 'edit',
    },
    itemToEdit: null,
    search: '',
    headers: [
      {
        text: 'Name',
        value: 'displayName',
        align: 'left',
      },
      {
        text: 'Email',
        value: 'email',
        align: 'left',
        sortable: true,
      },
      {
        text: 'Phone Number',
        value: 'phoneNumber',
        align: 'left',
        sortable: true,
      },
      {
        text: 'Type',
        value: 'role',
        align: 'left',
        sortable: true,
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters('user', ['userLoading', 'users']),
  },
  created() {
    this.getAllUsers()
  },
  methods: {
    ...mapActions('user', ['deleteUser', 'getAllUsers']),

    deleteUserClick({ uid }) {
      confirm('Are you sure you want to delete this item?') &&
        this.deleteUser({ userId: uid })
    },
    newUser() {
      this.dialogType = this.dialogTypes.NEW
      this.dialogOpen = true
    },
    editUser(item) {
      this.dialogType = this.dialogTypes.EDIT
      this.dialogOpen = true
      this.itemToEdit = item
    },
  },
}
</script>
