<template>
  <v-dialog v-model="dialogOpen" max-width="600px">
    <v-card class="elevation-0" outlined>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form id="userForm" ref="formUserEl" lazy-validation @submit.prevent="checkSubmit">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="name"
                  outlined
                  dense
                  color="red darken-4"
                  :error-messages="requiredError($v.name, 'Username')"
                  label="Username"
                  @change="$v.name.$touch"
                  @blur="$v.name.$touch"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="email"
                  outlined
                  dense
                  color="red darken-4"
                  :error-messages="requiredEmailError"
                  label="Email"
                  @change="$v.email.$touch"
                  @blur="$v.email.$touch"
                ></v-text-field>
              </v-col>
              <v-col v-if="dialogType === 'new'" cols="6">
                <v-text-field
                  v-model="password"
                  outlined
                  dense
                  color="red darken-4"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  :error-messages="requiredPasswordError"
                  @change="$v.password.$touch"
                  @blur="$v.password.$touch"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
              <v-col v-else cols="6">
                <v-btn color="red" @click="resetPassword">Reset User Password</v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="phone"
                  outlined
                  dense
                  color="red darken-4"
                  label="Phone number"
                  :error-messages="requiredPhoneError"
                  @change="$v.phone.$touch"
                  @blur="$v.phone.$touch"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="type"
                  outlined
                  dense
                  color="red darken-4"
                  :items="userRoles"
                  label="Type"
                  :error-messages="requiredError($v.type, 'Type')"
                  @change="$v.type.$touch"
                  @blur="$v.type.$touch"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="red darken-4" text @click="close">Cancel</v-btn>
        <v-btn color="green darken-4" text type="submit" form="userForm">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { mobilePhoneValidator, validateError } from '@utils/validateFields'
import {
  email,
  required,
  requiredIf,
  minLength,
} from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
export default {
  name: 'UsersDialog',
  components: {},
  mixins: [validationMixin],
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    dialogType: { type: String, default: 'new' },
    dialogTypes: { type: Object, default: () => {} },
    itemToEdit: { type: Object, default: () => {} },
  },
  validations() {
    return {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required: requiredIf(() => this.dialogType === this.dialogTypes.NEW),
        minLength: minLength(8),
      },
      phone: {
        required,
        mobilePhoneValidator,
      },
      type: {
        required,
      },
    }
  },
  data() {
    return {
      type: null,
      name: null,
      show: false,
      email: null,
      phone: null,
      previousEmail: null,
      dialogUserId: null,
      password: null,
      userRoles: ['admin', 'editor'],
    }
  },
  computed: {
    formTitle() {
      return this.dialogType === this.dialogTypes.NEW ? 'New User' : 'Edit User'
    },
    requiredEmailError() {
      return validateError(
        this.$v.email,
        ['required', 'email'],
        'Email is required and should be valid'
      )
    },
    requiredPasswordError() {
      return validateError(
        this.$v.password,
        ['required', 'minLength'],
        'Password is required and should be at least 8 characters long'
      )
    },
    requiredPhoneError() {
      return validateError(
        this.$v.phone,
        ['required', 'mobilePhoneValidator'],
        'Mobile phone is required and should be valid'
      )
    },
  },
  watch: {
    dialogOpen(dialogOpen) {
      if (dialogOpen && this.dialogType === this.dialogTypes.EDIT) {
        this.dialogUserId = this.itemToEdit.uid
        this.name = this.itemToEdit.displayName
        this.phone = this.itemToEdit.phoneNumber
        this.previousEmail = this.itemToEdit.email
        this.email = this.itemToEdit.email
        this.type = this.itemToEdit.role
      }
    },
  },
  methods: {
    ...mapActions({
      createUser: 'user/createUser',
      setResetPasswordEmail: 'auth/setResetPasswordEmail',
      updateUser: 'user/updateUser',
    }),
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    resetPassword() {
      this.setResetPasswordEmail(this.previousEmail)
      this.close()
    },
    validatePhone(phone) {
      return mobilePhoneValidator(phone) || 'Phone number is required and valid'
    },
    checkSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // if we create a new user, it should be mandatory to send all fields
        if (this.dialogType === this.dialogTypes.NEW) {
          const payload = {
            displayName: this.name,
            email: this.email,
            password: this.password,
            role: this.type,
            phoneNumber: this.phone,
          }
          this.createUser(payload)
          this.close()
          return
        }

        // instead, they can be optional in case of edit
        const payload = {
          ...(this.name && { displayName: this.name }),
          ...(this.email && { email: this.email }),
          ...(this.type && { role: this.type }),
          ...(this.phone && { phoneNumber: this.phone }),
          ...(this.dialogUserId && { userId: this.dialogUserId }),
        }
        this.updateUser(payload)
        this.close()
      }
    },
    close() {
      this.dialogUserId = null
      this.type = null
      this.name = null
      this.email = null
      this.password = null
      this.phone = null
      this.$refs.formUserEl.reset()
      this.$emit('closeDialog')
    },
  },
}
</script>
<style lang="">
</style>